.rootLayoutLogo {
  height: 28px;
  margin: 16px 0 30px;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.logoImg {
  width: 100%;
}

.layoutRight {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  :global {
    .ant-layout-content {
      display: flex;
      flex: 2;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
      overflow: auto;
    }
  }

  .layoutHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 40px;
    padding: 0;
    background: #fff;
    box-shadow:
      0 1px 4px 0 #f7f9fd,
      2px 4px 8px 0 #dde8f4;
  }
}

.layoutIframe {
  width: 100%;
  min-width: 1200px;
  height: 100%;
}

#menuIcon {
  width: 20px;
  margin-right: 0;
  margin-bottom: 6px;
}

.menuName {
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  text-overflow: ellipsis;
  margin-inline-start: 0 !important;
  margin-top: 4px;
}

.rootSubMenu {
  position: absolute;
  top: 0;
  left: 60px;
  z-index: 100001;
  display: flex;
  width: calc(100% - 60px);
  height: 100%;
  transition: visibility 0.5s 0s;
}

.rootSubMenuMask {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  transition: opacity 0.5s;
}

.rootSubMenuContent {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  transition: max-width 0.5s;
}

.rootSubMenuLine {
  height: 100%;
  border-left: 1px solid #f0f0f0;
}

.rootSubMenuBlock {
  height: auto;
  margin-bottom: 86px;
  padding-left: 36px;
}

.rootSubMenuBlockList {
  display: flex;
  align-items: center;
  color: #253041;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    .rootSubMenuBlockIcon,
    .rootSubMenuBlockItem {
      color: #2387fa !important;
    }
  }
}

.rootSubMenuBlockIcon {
  margin-right: 6px;
  font-size: 18px;
}

.rootSubMenuBlockTitle {
  color: #1978ff;
  font-weight: bold;
}

.rootSubMenuBlockItem {
  cursor: pointer;
}

#headerTabs {
  flex: 1;
  height: 40px;
  padding-left: 12px;
  text-shadow: none;

  :global {
    .ant-tabs-nav {
      top: 4px;

      &::before {
        border-bottom: none;
      }
    }

    .ant-tabs-tab {
      height: 36px;
      padding: 0 8px;
      color: #333;
      background: #fff;
      border: none;
      transition: none;

      &:hover {
        color: #333;
        background: #00000008;
        border-radius: 10px 10px 0 0;
      }
    }

    .ant-tabs-tab-active {
      color: #1890ff;
      background: #eef2ff;
      border-radius: 10px 10px 0 0;
    }

    .ant-tabs-tab-remove {
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
      font-size: 12px;
      border-radius: 50%;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .tabContent {
    display: flex;
    align-items: center;
    height: 36px;
  }

  .tabIcon {
    font-size: 16px;
  }
}

.headerTabItem {
  margin: 0 12px 0 4px;
  font-weight: 400;
  font-size: 12px;
  text-shadow: none;
}

#headerUser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  margin-right: 20px;
  line-height: 40px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 12px;
  }

  .name {
    margin: 0 10px;
    color: #333;
    font-size: 12px;
    cursor: pointer;
  }

  .clothesUnionButton {
    display: inline-block;
    width: 76px;
    height: 20px;
    margin-left: 2px;
    padding: 0;
    color: #21304d;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 4px #f7f9fd;
    background: linear-gradient(133deg, #e8f0ff 0%, #d4ebfa 42%, #ddf0ff 100%);
    border: 1px solid #6191d0;
    border-radius: 10px;
    box-shadow:
      0 1px 4px 0 #f7f9fd,
      0 1px 0 0 #6191d0;
  }

  .clothesUnionButton:hover {
    color: #fff;
    text-shadow: none;
    background: rgb(25, 120, 255);
  }

  .splitIcon {
    margin-right: 16px;
    margin-left: 16px;
    color: #e2e2e5;
  }

  .switchRoleWrapper {
    height: 20px;
    padding-left: 4px;
    color: #ff9948;
    background: rgba(255, 153, 72, 0.1);
    cursor: pointer;

    .switchRole {
      position: relative;
      top: -10px;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  .bgBlue {
    color: #1978ff;
    background: #f7f9fd;
  }
}

#layoutHome {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.rootLayout {
  height: 100vh;
  overflow: hidden;
}

.rootLayoutSider {
  > div {
    display: flex;
    flex-direction: column;
  }

  height: 100vh;
  color: #fff;

  :global {
    .ant-menu-item {
      height: max-content;
      margin: 0 !important;
      padding: 0 !important;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }
    }

    .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }

  .menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 12px;
  }
}

.headerTabs {
  flex: 2 !important;
  overflow: hidden !important;
}

.labelPage {
  display: flex;
  align-items: center;
  width: 110px;
}

.labelPageIcon {
  margin-right: 6px;
  margin-bottom: 2px;
  margin-left: 3px;
  font-size: 11px;
}

.unionGuideMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.7);

  .unionGuideImg {
    width: 600px;
    margin-right: 120px;
  }

  .buttonBox {
    display: flex;
    margin-top: 32px;

    .quiteButton {
      width: 163px;
      height: 58px;
      margin-right: 21px;
      color: #fff;
      font-weight: 400;
      font-size: 24px;
      line-height: 58px;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 29px;
      cursor: pointer;
    }

    .jumpButton {
      width: 163px;
      height: 58px;
      margin-right: 401px;
      color: #fff;
      font-weight: 400;
      font-size: 24px;
      line-height: 58px;
      text-align: center;
      background: linear-gradient(135deg, #4b6dff 0%, #1577ff 100%);
      border-radius: 29px;
      cursor: pointer;
    }
  }
}

.icon {
  font-size: 20px !important;
  margin-bottom: 2px;
}
